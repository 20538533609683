import React, { Component } from 'react';

import Big from 'big.js'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';

import Simulator from './Simulator'
import { Config } from '../Types'

const styles = (theme: Theme) => createStyles({
    row: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    container: {
        overflow: "hidden",
        width: "100%",
        position: "absolute",
        bottom: 0,
        left: 0,
    }
  });

interface Props extends WithStyles<typeof styles> {
  config: Config,
}

interface State {
}
  
// function paramUpdate(index: number, l: number, c: number, r: number) {
//   let ll = l ?? 0
//   let cc = c ?? 0 // Should never be used
//   let rr = r ?? 0
//   let x = 4 * ll + 2 * cc + rr
//   // get xth bit of index
//   let xth = (index >> x) & 1
//   // console.log("Result for " + index + " and " + x + ": " + xth)
//   return xth
// }

// function paramUpdate(index: number, l: number, c: number, r: number) {
//   let ll = l ?? 0
//   let cc = c ?? 0 // Should never be used
//   let rr = r ?? 0
//   let radix = this.props.config.numStates
//   let x = 4 * ll + 2 * cc + rr
//   // get xth bit of index
//   let xth = (index >> x) & 1
//   // console.log("Result for " + index + " and " + x + ": " + xth)
//   return xth
// }

class SpaceFillingAutomaton extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.update = this.update.bind(this)
  }

  private paramUpdate(index: Big, l: number, c: number, r: number) {
    let ll = l ?? 0
    let cc = c ?? 0 // Should never be used
    let rr = r ?? 0
    let radix = this.props.config.numStates
    let x = radix * radix * ll + radix * cc + rr
    // let sizeRequired = Math.ceil(Math.log2(radix))
    // get xth bit of index
    let xth = this.getIth(index, x, radix)
    // console.log("Result for " + index + " and " + x + ": " + xth)
    return xth
  }

  private getIth(n: Big, position: number, radix: number): number {
    let mod = n.mod(radix)
    if (position <= 0) {
      return mod.toNumber()
    } else {
      return this.getIth(n.sub(mod).div(radix), position - 1, radix)
    }
  }
  
  private update(l: number, c: number, r: number) {
    // console.log("l: " + l + " c: " + c + " r: " + r)
    // return ((l ?? 0) + (c ?? 0) + (r ?? 0)) % 2'
    return this.paramUpdate(this.props.config.updateFunctionId, l, c, r)
  }

  render() {
      const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Simulator update={this.update} config={this.props.config} />
      </div>
    );
  }
}

export default withStyles(styles)(SpaceFillingAutomaton);