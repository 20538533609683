
import Big, { BigSource } from 'big.js'

class Util {
    nums(l: number) {
        let ret = []
        for (let i = 0; i < l; i++) {
            ret.push(i)
        }
        return ret
    }

    falses(l: number) {
        let ret = []
        for (let i=0; i<l; i++) {
            ret.push(false)
        }
        return ret
    }

    getOrdinal(i: number) {
        if (i < 0) {
            throw new Error("Cannot make ordinal for negative (" + i + ")")
        }
        switch (i) {
            case 0: return "First"
            case 1: return "Second"
            case 2: return "Third"
            case 3: return "Fourth"
            case 4: return "Fifth"
        }

        throw new Error("Could not make ordinal for " + i)
    }

    bigMax(a: BigSource, b: BigSource): Big {
        if (Big(a).gt(b)) {
            return Big(a);
        } else {
            return Big(b);
        }
    }

    bigMin(a: BigSource, b: BigSource): Big {
        if (Big(a).lt(b)) {
            return Big(a);
        } else {
            return Big(b);
        }
    }
}

export default new Util()