import React, { Component } from 'react';

import Big from 'big.js'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { Config } from '../Types'

import Util from '../Util'
import Constants from '../Constants'

const styles = (theme: Theme) => createStyles({
  container: {
    // display: "flex",
    // flexDirection: "column",
  },
  main: {
    zIndex: 0,
  },
  categories: {
    maxHeight: '60vh',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  categoriesTab: {
    display: 'flex',
    flexDirection: 'column',
  },
  classification: {
    backgroundColor: theme.palette.secondary.main,
    width: 50,
    height: 50,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  legend: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  legendContainer: {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    padding: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
});



function getOnesDigits(n: Big): Big {
  if (n === undefined || n.lte(0)) {
    return Big(0)
  }
  let m = n.mod(2)
  return getOnesDigits(n.sub(m).div(2)).plus(m)
}

// NOTE: Most of these are 2-state specific
interface CategoryOptions {
  title: string,
  legendTitle: string,
  categoryCount: number,
  getColorIndex: (f: Big) => number,
  getLabel: (c: number) => string,
}

const classesOptions = {
  title: "Wolfram Classes [Tentative]",
  legendTitle: "Class",
  categoryCount: 4,
  getColorIndex: (f: Big) => (Constants.CLASSES_TWO_STATES[f.toNumber()] ?? 1) - 1,
  getLabel: (c: number) => (c + 1).toString(),
}

const bitSumOptions = {
  title: "Bit sums",
  legendTitle: "Bits set",
  categoryCount: 9,
  getColorIndex: (f: Big) => getOnesDigits(f).toNumber(),
  getLabel: (c: number) => c.toString(),
}

const nonPowerIndices = [0, 1, 2, 3, 5, 6, 7, 10, 11, 12, 13, 14, 15]
const powersOptions = {
  title: "Powers",
  legendTitle: "Base",
  categoryCount: nonPowerIndices.length + 1,
  getColorIndex: (c: Big) => {
    for (let ii=0; ii<=nonPowerIndices.length; ii++) {
      let i = nonPowerIndices[ii]
      for (let j=2; j<=7; j++) {
        if (Big(i).pow(j) === c) {
          return ii + 1
        }
      }
    }
    return 0
  },
  getLabel: (c: number) => c === 0 ? "N/A" : nonPowerIndices[c-1].toString(),
}

// https://mathworld.wolfram.com/ElementaryCellularAutomaton.html
const amphichiralIndices = [ 0, 1, 4, 5, 18, 19, 22, 23, 32, 33, 36, 37, 50, 51, 54, 55, 72, 73, 76, 77, 90, 91, 94, 95, 104, 105, 108, 109, 122, 123, 126, 127, 128, 129, 132, 133, 146, 147, 150, 151, 160, 161, 164, 165, 178, 179, 182, 183, 200, 201, 204, 205, 218, 219, 222, 223, 232, 233, 236, 237, 250, 251, 254, 255]
const amphichiralOptions = {
  title: "Amphichiral",
  legendTitle: "Is Amphichiral",
  categoryCount: 2,
  getColorIndex: (c: Big) => amphichiralIndices.includes(c.toNumber()) ? 1 : 0,
  getLabel: (c: number) => c === 0 ? "No" : "Yes"
}

// NOTE: Appears to be missing some... Are they unique?
// https://oeis.org/wiki/Index_to_Elementary_Cellular_Automata#Equivalent_Elementary_Cellular_Automata
const equivalenceClasses = [
  [0,8,32,40,64,72,96,104,128,136,160,168,192,200,224,232],
  [1,33],
  [2,10,34,42,66,74,98,106,130,138,162,170,194,202,226,234],
  [3,35],
  [4,12,36,44,68,76,100,108,132,140,164,172,196,204,228,236],
  [6,38,134,166],
  [11,43],
  [14,46,142,174],
  [16,24,48,56,80,88,112,120,144,152,176,184,208,216,240,248],
  [17,49],
  [18,26,82,90,146,154,210,218],
  [20,52,148,180],
  [23,31,55,63,87,95,119,127],
  [28,156],
  [50,58,114,122,178,179,186,242,250],
  [70,198],
  [81,113],
  [84,116,212,244],
  [129,161],
  [139,171],
  [151,159,183,191,215,222,223,247,254,255],
  [206,238],
  [209,241],
  [220,252],
]
function getEquivalenceClass(n: number): number {
  for (let i = 0; i < equivalenceClasses.length; i++) {
    if (equivalenceClasses[i].includes(n)) {
      return i
    }
  }
  return -1
}
const equivalenceOptions = {
  title: "Equivalent Automata",
  legendTitle: "Equivalence class",
  categoryCount: equivalenceClasses.map(v => v.length).reduce((prev, curr) => Math.max(prev, curr)),
  getColorIndex: (c: Big) => getEquivalenceClass(c.toNumber()),
  getLabel: (c: number) => c.toString(),
}
console.log("number of represented classes: " + equivalenceClasses.map(v => v.length).reduce((prev, curr) => prev + curr))

// Probs want FunctionUtil for i.e. finding value of ith function applied to k
const preservesSpaceOptions = {
  title: "Space preservation",
  legendTitle: "Preserves space",
  categoryCount: 2,
  getColorIndex: (c: number) => c & (c >> 7) & 1,
  getLabel: (c: number) => c === 0 ? "No" : "Yes",
}

const options: CategoryOptions[] = [
  classesOptions,
  bitSumOptions,
  powersOptions,
  amphichiralOptions,
  equivalenceOptions,
  // preservesSpaceOptions,
]

interface Props extends WithStyles<typeof styles> {
  config: Config,
  updateConfig: (config: Config) => void,
  handleClose: () => void,
}

type State = {
  hoverIndex: Big,
  optionsIndex: number,
}

// TODO: Should be pure for perf
class ControlPanel extends Component<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      hoverIndex: Big(-1),
      optionsIndex: 0,
    }
  }

  getCategoryOptions() {
    return options[this.state.optionsIndex]
  }

  handleClose() {
    this.props.handleClose()
  }

  handleSelectUpdateFunction(updateFunctionId: Big) {
    let newConfig = {
      ...this.props.config,
      updateFunctionId: updateFunctionId,
    }
    this.props.updateConfig(newConfig)
    this.handleClose()
  }

  handleChange(event: React.ChangeEvent<{ value: unknown }>) {
    this.setState({
      optionsIndex: parseInt(event.target.value as string),
    })
  }

  private getBinary(n: number, digits: number) {
    let ret = ""
    for (let i = 0; i < digits; i++) {
      let m = n % 2
      ret = m + ret
      n = (n - m) / 2
    }
    return ret
  }

  render() {
    const { classes } = this.props

    let radix = this.props.config.numStates
    let inputCount = radix ** 3
    let functionCount = radix ** inputCount
    let portion = Math.round(Math.sqrt(functionCount))
    let digits = Math.round(Math.sqrt(portion))

    return (
      <div className={classes.categoriesTab}>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Categorization</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={this.state.optionsIndex}
            onChange={event => this.handleChange(event)}
          >
            {options.map((v, i) => {
              return (
                <MenuItem value={i} key={i}>{v.title}</MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <div className={classes.row}>
            <div className={classes.classification}>
              {}
            </div>
            {Util.nums(portion).map(v => {
              return (
                <div className={classes.classification} key={v}>
                  {this.getBinary(v, digits)}
                </div>
              )
            })}
            <div className={classes.classification}>
              {}
            </div>
          </div>
        <div className={classes.categories}>
          {Util.nums(portion).map(v => {
            return (
              <div key={v} className={classes.row}>
                <div className={classes.classification}>
                  {this.getBinary(v, digits)}
                </div>
                {Util.nums(portion).map(w => {
                  let val = Big(v).times(portion).plus(w)
                  let colorIndex = this.getCategoryOptions().getColorIndex(val)

                  return (
                    <div
                      className={classes.classification}
                      key={w}
                      // onMouseEnter={() => this.enter(v)}
                      // onMouseLeave={() => this.exit(v)}
                      onClick={() => this.handleSelectUpdateFunction(val)}
                      style={{ backgroundColor: this.props.config.colorMap.get(colorIndex), opacity: this.state.hoverIndex !== val ? "1" : "0.5" }}
                    >
                      {val.toFixed()}
                    </div>
                  )
                })}
                <div className={classes.classification}>
                  {this.getBinary(v, digits)}
                </div>
              </div>
            )
          })}
        </div>

        <div className={classes.legendContainer}>
          <div>{this.getCategoryOptions().legendTitle}</div>
          <div className={classes.legend}>
            {Util.nums(this.getCategoryOptions().categoryCount).map(v => {
              return (
                <div className={classes.classification} key={v} style={{ backgroundColor: this.props.config.colorMap.get(v) }}>
                  {this.getCategoryOptions().getLabel(v)}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ControlPanel);