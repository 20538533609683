import React, { Component } from 'react';

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';

import Constants from '../Constants'
import { Config } from '../Types'

const styles = (theme: Theme) => createStyles({
  cell: {
    padding: Constants.CELL_SIZE / 2,
  },
  cellSmall: {
    padding: 2,
  },
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  }
});

// TODO: Probably want RowData type which includes generation?
interface Props extends WithStyles<typeof styles> {
  cells: number[],
  config: Config,
  generation: number,
  small?: boolean,
}

type State = {

}

// TODO: Should be pure for perf
class Container extends Component<Props, State> {

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    let currConfig = this.props.config
    let nextConfig = nextProps.config
    if (currConfig.numStates !== nextConfig.numStates) {
      // console.log("Row SCU: state count changed")
      return true
    }
    for (let i=0; i<currConfig.numStates; i++) {
      if (currConfig.colorMap.get(i) !== nextConfig.colorMap.get(i)) {
        // console.log("Row SCU: color for state " + i + " changed")
        return true
      }
    }
    let cellsChanged = this.props.cells !== nextProps.cells
    // console.log("Row SCU: cells changed " + cellsChanged)
    return cellsChanged
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.row}>
          {this.props.cells.map((v, i) => {
            return (
              <div className={this.props.small ?? false ? classes.cellSmall : classes.cell} style={{backgroundColor: this.props.config.colorMap.get(v)}} key={i}>
                {}
              </div>
            )
          })}
      </div>
    );
  }
}

export default withStyles(styles)(Container);