import React, { Component } from 'react';

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab'

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Config } from '../Types'

const styles = (theme: Theme) => createStyles({
  container: {
  },
  swatch: {
  },
  main: {
    zIndex: 0,
  },
  popover: {
    position: 'absolute',
    zIndex: 2,
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  picker: {
    position: 'relative',
    left: '-150px',
  }
});

interface Props extends WithStyles<typeof styles> {
  config: Config,
  updateConfig: (config: Config) => void,
}

type State = {
  tempIndex: number,
  dialogOpen: boolean,
}

// TODO: Should be pure for perf
class ControlPanel extends Component<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      tempIndex: 30,
      dialogOpen: false,
    }
  }

  handleClose() {
    this.setState({
      dialogOpen: false,
    })
  }

  handleOpen() {
    this.setState({
      tempIndex: this.props.config.numStates,
      dialogOpen: true,
    })
  }

  handleUpdate = () => {
    console.log("updating to " + this.state.tempIndex+ " states")
    let newConfig = {
      ...this.props.config,
      numStates: this.state.tempIndex,
    }
    this.props.updateConfig(newConfig)
    this.handleClose()
  }

  handleMinus = () => {
    let val = Math.max(0, this.props.config.numStates - 1)
    let newConfig = {
      ...this.props.config,
      numStates: val,
    }
    this.props.updateConfig(newConfig)
  }

  handlePlus = () => {
    let max = 4
    let proposed = this.props.config.numStates + 1
    let val = Math.min(max, proposed)
    let newConfig = {
      ...this.props.config,
      numStates: val,
    }
    this.props.updateConfig(newConfig)
  }

  handleTextChange = (ev: any) => {
    this.setState({
      tempIndex: parseInt(ev.target.value),
    })
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.container}>
        <Fab color="secondary" size="small" onClick={() => this.handleMinus()}>
          -
        </Fab>
        <Fab color="secondary" className={classes.main} onClick={() => this.handleOpen()}>
          {this.props.config.numStates}
        </Fab>
        <Fab color="secondary" size="small" onClick={() => this.handlePlus()}>
          +
        </Fab>
        <Dialog open={this.state.dialogOpen} onClose={() => this.handleClose()} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Num states</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Pick the number of states for this automaton
                </DialogContentText>
                <TextField
                  autoFocus
                  value={this.state.tempIndex}
                  onChange={ev => this.handleTextChange(ev)}
                  margin="dense"
                  id="update"
                  label="Update Index"
                  type="number"
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.handleClose()} color="primary">
                  Cancel
              </Button>
                <Button onClick={() => this.handleUpdate()} color="primary">
                  Update
              </Button>
              </DialogActions>
            </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(ControlPanel);