import React, { Component } from 'react';

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab'

import UpdateFunctionDialog from './UpdateFunctionDialog'

import { Config } from '../Types'
import Util from '../Util'

const styles = (theme: Theme) => createStyles({
  container: {
  },
  main: {
    zIndex: 0,
  },
});

interface Props extends WithStyles<typeof styles> {
  config: Config,
  updateConfig: (config: Config) => void,
}

type State = {
  dialogOpen: boolean,
  tab: number,
  openedPicker: number,
  hoverIndex: number,
}

// TODO: Should be pure for perf
class ControlPanel extends Component<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      dialogOpen: false,
      tab: 0,
      openedPicker: -1,
      hoverIndex: -1,
    }
  }

  handleClose() {
    this.setState({
      dialogOpen: false,
    })
  }

  handleOpen() {
    this.setState({
      dialogOpen: true,
    })
  }

  handleMinus = () => {
    let val = Util.bigMax(0, this.props.config.updateFunctionId.sub(1))
    let newConfig = {
      ...this.props.config,
      updateFunctionId: val,
    }
    this.props.updateConfig(newConfig)
  }

  handlePlus = () => {
    // console.log("Curr update function id " + this.props.config.updateFunctionId)
    let max = this.props.config.numStates ** (this.props.config.numStates ** 3)
    // console.log("max: " + max)
    let proposed = this.props.config.updateFunctionId.plus(1)
    // console.log("proposed: " + proposed)
    let val = Util.bigMin(max, proposed)
    // console.log("val: " + val)
    let newConfig = {
      ...this.props.config,
      updateFunctionId: val,
    }
    this.props.updateConfig(newConfig)
  }

  render() {
    const { classes } = this.props

    let maxLen = 5
    let asFixed = this.props.config.updateFunctionId.toFixed()
    let forDisplay = asFixed.length <= maxLen ? asFixed : this.props.config.updateFunctionId.toPrecision(maxLen - 3)

    return (
      <div className={classes.container}>
        <Fab color="secondary" size="small" onClick={() => this.handleMinus()}>
          -
        </Fab>
        <Fab color="secondary" className={classes.main} onClick={() => this.handleOpen()}>
          {forDisplay}
        </Fab>
        <Fab color="secondary" size="small" onClick={() => this.handlePlus()}>
          +
        </Fab>
        <UpdateFunctionDialog open={this.state.dialogOpen} handleClose={() => this.handleClose()} config={this.props.config} updateConfig={this.props.updateConfig} />
      </div >
    );
  }
}

export default withStyles(styles)(ControlPanel);