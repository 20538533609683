import React, { Component } from 'react';

import Big from 'big.js'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import { AlternatingEdgeHandling, Config, ConstantSidesEdgeHandling, EdgeHandling, EdgeHandlingType, RandomEdgeHandling, WrapEdgeHandling } from '../Types'
import Constants from '../Constants';
import Util from '../Util'

const styles = (theme: Theme) => createStyles({
  container: {
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  settings: {
    display: "flex",
    flexDirection: "row",
    alignItems: 'center',
  },
  cell: {
    padding: Constants.CELL_SIZE,
    width: 0,
    border: "solid",
  },
  popoverContents: {
    backgroundColor: '#aaaaaa',
    display: "flex",
    flexDirection: 'row',
    alignItems: 'center',
    padding: 5,
  },
  popover: {
    position: 'relative',
    zIndex: 2,
  },
  colorPicker: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  additionalSetting: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  weight: {
    display: 'flex',
    flexDirection: 'row',
  },
  tf: {
    maxWidth: 80,
  },
});

interface Props extends WithStyles<typeof styles> {
  config: Config,
  updateConfig: (config: Config) => void,
  handleClose: () => void,
}

type State = {
  index: number,
  openedPicker: number,
}

const constantSidesDefault: ConstantSidesEdgeHandling = {
  t: EdgeHandlingType.Constant,
  leftColorIndex: 0,
  rightColorIndex: 1,
}

const alternatingDefault: AlternatingEdgeHandling = {
  t: EdgeHandlingType.Alternating,
  firstColorIndex: 0,
  secondColorIndex: 1,
}

const randomDefault: RandomEdgeHandling = {
  t: EdgeHandlingType.Random,
}

const wrapDefault: WrapEdgeHandling = {
  t: EdgeHandlingType.Wrap,
}

const handlingTypes = [
  ["Constant", constantSidesDefault],
  ["Alternating", alternatingDefault],
  ["Random", randomDefault],
  ["Wrap", wrapDefault],
]

// TODO: Should be pure for perf
class ControlPanel extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      index: 0,
      openedPicker: -1,
    };
  }

  handleClose() {
    this.props.handleClose()
  }

  handleSelectUpdateFunction(updateFunctionId: Big) {
    let newConfig = {
      ...this.props.config,
      updateFunctionId: updateFunctionId,
    }
    this.props.updateConfig(newConfig)
    this.handleClose()
  }

  handleChange(event: React.ChangeEvent<{ value: unknown }>) {
    let i = parseInt(event.target.value as string)
    this.setState({
      index: i,
    })
    let newConfig = {
      ...this.props.config,
      edgeHandling: this.getEdgeHandling(i),
    }
    this.props.updateConfig(newConfig)
  }

  getEdgeHandling(i: number): EdgeHandling {
    return handlingTypes[i][1] as EdgeHandling
  }

  getEdgeHandlingIndex() {
    let st = this.props.config.edgeHandling.t
    for (let i = 0; i < handlingTypes.length; i++) {
      if (st === (handlingTypes[i][1] as EdgeHandling).t) {
        return i
      }
    }
    return 0
  }

  shouldShowColorPickers(edgeHandlingType: EdgeHandlingType) {
    return edgeHandlingType === EdgeHandlingType.Constant || edgeHandlingType === EdgeHandlingType.Alternating
  }
  
  handleTextChange = (ev: any, i: number) => {
    let edgeHandling = this.props.config.edgeHandling as RandomEdgeHandling
    let weights = edgeHandling.weights
    if (weights === undefined) {
      weights = [1,1,1,1] // NOTE: assumes at most 4 states
    }
    weights[i] = parseInt(ev.target.value)
    edgeHandling.weights = weights
    let newConfig = {
      ...this.props.config,
      edgeHandling: edgeHandling,
    }
    this.props.updateConfig(newConfig)
  }

  handleFirstPicked(i: number) {
    this.setState({
      openedPicker: i,
    })
  }

  handleOutputChange(i: number, c: number) {
    let edgeHandling = this.props.config.edgeHandling
    if (edgeHandling.t === EdgeHandlingType.Constant) {
      if (i === 0) {
        edgeHandling.leftColorIndex = c
      } else if (i === 1) {
        edgeHandling.rightColorIndex = c
      }
    } else if (edgeHandling.t === EdgeHandlingType.Alternating) {
      if (i === 0) {
        edgeHandling.firstColorIndex = c
      } else if (i === 1) {
        edgeHandling.secondColorIndex = c
      }
    }
    let newConfig = {
      ...this.props.config,
      edgeHandling: edgeHandling,
    }
    this.props.updateConfig(newConfig)
    this.setState({
      openedPicker: -1,
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.settings}>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Edge Handling</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={this.getEdgeHandlingIndex()}
            onChange={e => this.handleChange(e)}
          >
            {handlingTypes.map((v, i) => {
              return (
                <MenuItem value={i} key={i}>{v[0]}</MenuItem>
              )
            })}
          </Select>
        </FormControl>
        {!this.shouldShowColorPickers(this.props.config.edgeHandling.t) ? null : (
          [0, 1].map(v => {
            let edgeHandling = this.props.config.edgeHandling
            let t = edgeHandling.t
            let first = t === EdgeHandlingType.Constant ? (edgeHandling as ConstantSidesEdgeHandling).leftColorIndex : (edgeHandling as AlternatingEdgeHandling).firstColorIndex
            let second = t === EdgeHandlingType.Constant ? (edgeHandling as ConstantSidesEdgeHandling).rightColorIndex : (edgeHandling as AlternatingEdgeHandling).secondColorIndex
            let colorIndex = v === 0 ? first : second
            return (
              <div className={classes.additionalSetting} key={v}>
                <InputLabel id="demo-simple-select-label">{v === 0 ? "First" : "Second"} color</InputLabel>
                <div className={classes.cell} style={{ backgroundColor: this.props.config.colorMap.get(colorIndex) }} onClick={() => this.handleFirstPicked(v)} />
                {this.state.openedPicker !== v ? null : (
                  <div className={classes.popover} key={v}>
                    <div className={classes.popoverContents}>
                      {Util.nums(this.props.config.numStates).map(v2 => {
                        return (
                          <div key={v2} className={classes.cell} style={{ backgroundColor: this.props.config.colorMap.get(v2) }} onClick={() => this.handleOutputChange(v, v2)} />
                        )
                      })}
                    </div>
                  </div>
                )}
              </div>
            )
          })
        )}
        {this.props.config.edgeHandling.t !== EdgeHandlingType.Random ? null : (
          Util.nums(this.props.config.numStates).map(v => {
            let weights =(this.props.config.edgeHandling as RandomEdgeHandling).weights
            return (
              <div className={classes.additionalSetting} key={v}>
                <InputLabel id="demo-simple-select-label">{Util.getOrdinal(v)} weight</InputLabel>
                <div className={classes.weight}>
                  <div className={classes.cell} style={{ backgroundColor: this.props.config.colorMap.get(v) }} />
                  <TextField
                    className={classes.tf}
                    value={weights === undefined ? 1 : weights[v]}
                    onChange={ev => this.handleTextChange(ev, v)}
                    type="number"
                  />
                </div>
              </div>
            )
          })
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ControlPanel);