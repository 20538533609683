import React, { Component } from 'react';

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Config } from '../Types'
import InitialStateSection from './InitialStateSection';
import UpdateSection from './UpdateSection';

const styles = (theme: Theme) => createStyles({
  container: {
  },
});

interface Props extends WithStyles<typeof styles> {
  config: Config,
  updateConfig: (config: Config) => void,
  open: boolean,
  handleClose: () => void,
}

type State = {
  tempConfig: Config,
}

// TODO: Should be pure for perf
class ControlPanel extends Component<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      tempConfig: this.props.config,
    }

    this.updateTempConfig = this.updateTempConfig.bind(this)
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        tempConfig: this.props.config,
      })
    }
  }

  handleClose() {
    this.props.handleClose()
  }

  handleUpdate = () => {
    this.props.updateConfig(this.state.tempConfig)
    this.handleClose()
  }

  updateTempConfig(config: Config) {
    this.setState({
      tempConfig: config,
    })
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.container}>
        <Dialog maxWidth="lg" open={this.props.open} onClose={() => this.handleClose()} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Advanced Settings</DialogTitle>
          <DialogContent>
            <div>
                <InitialStateSection handleClose={() => this.handleClose()} config={this.state.tempConfig} updateConfig={this.updateTempConfig} />
                <UpdateSection handleClose={() => this.handleClose()} config={this.state.tempConfig} updateConfig={this.updateTempConfig} />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose()} color="primary">
              Cancel
              </Button>
            <Button onClick={() => this.handleUpdate()} color="primary">
              Update
              </Button>
          </DialogActions>
        </Dialog>
      </div >
    );
  }
}

export default withStyles(styles)(ControlPanel);