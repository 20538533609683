import React, { Component } from 'react';

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';

import SettingsIcon from '@material-ui/icons/Settings'

import Fab from '@material-ui/core/Fab'

import { Config } from '../Types'
import AdvancedSettingsDialog from './AdvancedSettingsDialog';

const styles = (theme: Theme) => createStyles({
  container: {
  },
  main: {
    zIndex: 0,
  },
  fab: {
    margin: '5px',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    justifyItems: "center",
  },
});

interface Props extends WithStyles<typeof styles> {
  config: Config,
  updateConfig: (config: Config) => void,
}

type State = {
  dialogOpen: boolean,
}

// TODO: Should be pure for perf
class ControlPanel extends Component<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      dialogOpen: false,
    }
  }

  handleClose() {
    this.setState({
      dialogOpen: false,
    })
  }

  handleOpen() {
    this.setState({
      dialogOpen: true,
    })
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.container}>
        <Fab color="secondary" className={classes.fab} onClick={() => this.handleOpen()} >
          <SettingsIcon />
        </Fab>
        <AdvancedSettingsDialog open={this.state.dialogOpen} handleClose={() => this.handleClose()} config={this.props.config} updateConfig={this.props.updateConfig} />
      </div >
    );
  }
}

export default withStyles(styles)(ControlPanel);