import React, { Component } from 'react';

import Big from 'big.js'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

import { Config } from '../Types'
import Trace from './Trace';
import Util from '../Util'

const styles = (theme: Theme) => createStyles({
  container: {
  },
  presets: {
    display: "flex",
    flexDirection: 'column',
  },
  presetRow: {
    display: 'flex',
    flexDirection: 'row',
  },
});

interface Props extends WithStyles<typeof styles> {
  config: Config,
  updateConfig: (config: Config) => void,
  handleClose: () => void,
}

type State = {
}

// TODO: Should be pure for perf
class ControlPanel extends Component<Props, State> {

  handleClose() {
    this.props.handleClose()
  }

  handleSelectUpdateFunction(updateFunctionId: Big) {
    let newConfig = {
      ...this.props.config,
      updateFunctionId: updateFunctionId,
    }
    this.props.updateConfig(newConfig)
    this.handleClose()
  }

  // NOTE: Stolen from SpaceFillingAutomaton
  private updateFun(index: Big, l: number, c: number, r: number) {
    let ll = l ?? 0
    let cc = c ?? 0 // Should never be used
    let rr = r ?? 0
    let radix = this.props.config.numStates
    let x = radix * radix * ll + radix * cc + rr
    // let sizeRequired = Math.ceil(Math.log2(radix))
    // get xth bit of index
    let xth = this.getIth(index, x, radix)
    // console.log("Result for " + index + " and " + x + ": " + xth)
    return xth
  }
  private getIth(n: Big, position: number, radix: number): number {
    let mod = n.mod(radix)
    if (position <= 0) {
      return mod.toNumber()
    } else {
      return this.getIth(n.sub(mod).div(radix), position - 1, radix)
    }
  }

  genTrace(f: Big) {
    // TODO: Update based on settings
    let sideLength = 30
    let initialState = {
      generation: 0,
      cells: Util.nums(sideLength).map(v => v === 15 ? 1 : 0) as number[],
    }
    let rows = [initialState]
    for (let i = 0; i < sideLength; i++) {
      let lastRow = rows[rows.length - 1]
      let nextRow = {
        generation: lastRow.generation + 1,
        cells: lastRow.cells.map((v, i, arr) => this.updateFun(f, i === 0 ? arr[arr.length - 1] : arr[i - 1], v, i === lastRow.cells.length - 1 ? arr[0] : arr[i + 1])),
      }
      rows.push(nextRow)
    }
    return (
      <div>
        <Trace rows={rows} config={this.props.config} small={true} />
      </div>
    )
  }

  genButton(f: Big) {
    return (
      <Button color="primary" onClick={() => this.handleSelectUpdateFunction(f)}>
        <div>
          <div>{f.toFixed()}</div>
          <div>{this.genTrace(f)}</div>
        </div>
      </Button>
    )
  }

  genPresets(nums: number[][]) {
    const { classes } = this.props
    return (
      <div className={classes.presets}>
            {nums.map(row => {
              return (
                <div key={row[0]} className={classes.presetRow}>
                  {row.map(f => {
                    return (
                      <div key={f}>
                        {this.genButton(Big(f))}
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
    )
  }

  render() {
    return (
      <div>
        {this.props.config.numStates === 2 ? this.genPresets([[30,73,105], [180,195,199]]) : null}
        {this.props.config.numStates === 3 ? this.genPresets([[3000001,43758902,5953133349723]]) : null}
      </div>
    );
  }
}

export default withStyles(styles)(ControlPanel);