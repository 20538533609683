import React, { Component } from 'react';

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import Fab from '@material-ui/core/Fab'

import { GithubPicker, ColorResult } from 'react-color'

const styles = (theme: Theme) => createStyles({
  container: {
  },
  swatch: {
  },
  popover: {
    position: 'absolute',
    zIndex: 2,
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  picker: {
    position: 'relative',
    left: '-150px',
  }
});

interface Props extends WithStyles<typeof styles> {
  color: string,
  updateColor: (color: string) => void,
}

type State = {
  displayColorPicker: boolean,
}

// TODO: Should be pure for perf
class ControlPanel extends Component<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      displayColorPicker: false,
    }
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  handleChange = (color: ColorResult) => {
    console.log("Color result: " + JSON.stringify(color.hex))
    this.props.updateColor(color.hex)
    this.handleClose()
  };

  render() {
    const { classes } = this.props

    return (
      <div className={classes.container} onClick={this.handleClick}>
        <Fab color="secondary">
          <FiberManualRecordIcon style={{ color: this.props.color }} />
        </Fab>
        {this.state.displayColorPicker ? <div className={classes.popover}>
          <div className={classes.cover} onClick={this.handleClose} />
          <GithubPicker color={this.props.color} onChange={this.handleChange} className={classes.picker} />
        </div> : null}
      </div>
    );
  }
}

export default withStyles(styles)(ControlPanel);