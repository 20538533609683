import React, { Component } from 'react';

import Big from 'big.js'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';

import SpaceFillingAutomaton from './ui/SpaceFillingAutomaton'
import NewPanel from './ui/NewPanel'
import Constants from './Constants'
import { Config, EdgeHandlingType, InitialStateType, RandomInitialState, WrapEdgeHandling } from './Types'

const styles = (theme: Theme) => createStyles({
  panel: {
    position: "absolute",
    bottom: Constants.CONTROL_PANEL_MARGIN,
    left: Constants.CONTROL_PANEL_MARGIN,
    backgroundColor: '#556677',
    zIndex: 100,
    visibility: "hidden",
  },
  newPanel: {
    position: "absolute",
    bottom: Constants.CONTROL_PANEL_MARGIN,
    right: Constants.CONTROL_PANEL_RIGHT_MARGIN,
    zIndex: 100,
  },
  automaton: {
    zIndex: -100,
  }
});

/**
 * Could have play/pause buttons, could specify which update function, also colors
 * Would be nice to make it choose number of spaces in row based on window width
 * Need to figure out how to make it scroll up once it's hit the bottom... Hmm...
 * 
 * So now I want to make it more efficient (pure components) and then I want to
 * add a reset button and allow selecting the update function. In future maybe want
 * to allow selecting starting state, too. Then maybe store this info locally.
 * 
 * So I should make another plan.
 * 0. Factor out create initial state V
 * 1. Add reset button V
 * 2. It clears out all the rows and makes new initial state V
 * 3. Default color map V
 * 4. Mapping from integer to update function V
 * 5. Allow choosing update function V
 * 6. Maybe panel should be at top?
 * 
 * Okay, I think I'm pretty close to putting this on the internet to show Evan...
 * 
 * OOOOH. What if it (control panel) were a fab that expands vertically? I could
 * keep it in the right corner and it might be usable with real content in front.
 * I'll also note the fab menu should be like this:
 * 0. Number of states (with +/- next to it)
 * 1. Colors (one to be picked for each state)
 * 2. Update function id (with +/-)
 * 3. Reset button
 * 4. Close button
 * [Maybe also have a more advance settings section for i.e. how treat sides]
 * [Maybe also make it possible to rate the colors or something? Later]
 * 
 * Made it past build, now aws sdk didn't work properly...
 * Finally got it up online
 * 
 * Should switch to cyclical boundary or random boundary -- death sneaks in easily
 * 
 * God, It's impossible just to get the state to update. I don't get why me updating
 * the array of booleans isn't working. Let's restart it, actually.
 * 
 * Time for a new plan:
 * 1. Get color picker to close V
 * 2. Cyclical boundary V
 * 3. Change update function (+/-) V
 * 4. Hide old control panel V
 * 5. Make panel grey V
 * 6. Release V
 * 7. Control number of states V
 * 8. Detect simple patterns and classify
 * 9. Report to some backend
 * 
 * IDEA: Make separate color picker fab so you don't have to deal with that array
 * of booleans in the state
 * 
 * IDEA: Special picker for update function can show a list with things such as
 * how many people have looked at it and for how long
 * 
 * IDEA: keyboard shortcuts
 * 
 * Would be really cool to categorize all of them for 2 states. Then you
 * could have those categories labeled when selecting.
 * Some cool ones I've found so far: 30, 73, 82, 86, 102, 105
 * 
 * So, top next couple of things to do:
 * 1. Control number of states V
 * 2. Only fill screen (efficiency) V
 * 3. Fix sizing on mobile V
 * 4. Function chooser show 6 interesting ones V
 * 5. Function chooser select each output (convert to function number and back) V
 * 6. Manually categorize for 2 states (find reference book?)
 * 7. Automatic categorization for higher numbers of states
 * 8. Allow setting starting condition (30% chance 0, all 1s, alternating 1 0, etc.)
 * 9. Enforce maximums in dialog, too
 * 
 * I feel like the buttons probably need to be labeled; I'll have to think some
 * more about maybe putting the label above the button? It just isn't possible to
 * have good symbology for this.
 * 
 * For 3: number 300000 has blue go on for a while. So I think it
 * is actually functional. Holy shit there are so many.
 * 3000001 is SUPER COOL!!!!
 * 
 * IDEA: Color palettes, i.e. Pastel, Google, Primary, ...
 * 
 * Okay, I think up next is making it possible to select the output for each possible input V
 * 
 * Okay, up next should be allowing changing a single output in the custom list and then
 * having it update the function index appropriately V
 * 
 * Also want to label the fabs V
 * 
 * TODO: Handle when function does not fit V
 * 
 * Another cool one: 2541870422653
 * 
 * TODO: Control panel too big for phones
 * 
 * Classifying automata: https://www.wolframscience.com/nks/p231--four-classes-of-behavior/
 * https://www.wolframscience.com/nks/p235--four-classes-of-behavior/
 * Class 1 - Almost all initial conditions lead to exactly the same uniform state
 * Class 2 - Many different possible final states, but each is a set of simple structures that either remain or repeat every few steps
 * Class 3 - More complicated, seems random with some triangles and other small-scale structures
 * Class 4 - Mixture of order and randomness: simple localized structures are produced but interact complexly
 * 
 * TODO: Check numbering system with https://www.wolframscience.com/nks/p234--four-classes-of-behavior/ (or earlier)
 * 
 * TODO: Update icon and title
 * 
 * IDEA: Show categories as table with bit sequences for first and second half?
 * 
 * IDEA: What if you could search the categories table? Like you see how ones with 5th bit set to 1 operate, or how
 * ones with 6 bits set operate
 * 
 * I think most important next thing is allow choose initial state, probably want that to be on a separate
 * advanced settings dialog.
 * 
 * IDEA: Generalize the class computation for the categories so you can actualy group by lots of different values
 * 
 * Okay, at this point I think I need to be doing refactoring, soon it will be too late for me to do this reasonably.
 * 
 * Okay, categories are looking pretty good. I probably want to implement a few more, and then I want to make the
 * filtering for it.
 * 
 * IDEA: make the trace slowly slide up from the bottom instead of being jumpy
 * 
 * NOTE: Function index in dialog does not update with others
 * 
 * Another TODO: Fix access on AWS (S3 publicly accessible?)
 * 
 * Would be nice to have a snapshot as the background of the presets
 * 
 * Probably need some big int implementation in order to do 4 states or more. Ugh.
 * 
 * Okay, I think we're in a pretty good place again. This is close to perfect. Here are the
 * few things that I can still think of wanting to do:
 * 1. Remove 'space preserving' category V
 * 2. Make amphichiral category
 * 3. Allow filtering (i.e. on graph of one category, gray out ones not matching a filter potentially from another category)
 * 4. Make control panel fit on smaller phones
 * 5. Investigate whether category can be 3-state ones equivalent to 2-state ones via some labeling function
 * 6. Maybe info page to show properties of an ECA, like (5) and (2) above and potentially others (Wolfram class automatic guess?)
 * 7. Report data about which ones are seen for how long to a server
 * 8. Allow random generation of a function
 * 9. Show data about how many people have seen it for how long
 * 10. Show certified classes (i.e. popular ones, e.g. rule 90) with a checkmark to differentiate from my or algo's guesses
 * 
 * IDEA: Info page to show which ones are equivalent
 * IDEA: Allow choosing dimensions of category chart (because looks like twos are important)
 * 
 * Updated to use big.js. Up next probably should indicate that it's not advised to use more than 4 states...
 * Also need to figure out how to do HTTPS redirect for Linode buckets...
 */


interface Props extends WithStyles<typeof styles>  {

}

interface State {
  config: Config,
}

const defaultInitialState : RandomInitialState = {
  state: InitialStateType.Random,
  firstColorIndex: 0,
  secondColorIndex: 0,
}

const defaultEdgeHandling : WrapEdgeHandling = {
  t: EdgeHandlingType.Wrap,
}

class Container extends Component<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      config: {
        numStates: 2,
        colorMap: Constants.DEFAULT_COLOR_MAP,
        version: 0,
        updateFunctionId: Big(30),
        initialState: defaultInitialState,
        edgeHandling: defaultEdgeHandling,
      },
    }
  }

  updateConfig(config: Config) {
    this.setState({
      config: config,
    })
  }

  render() {
    const { classes } = this.props
    return (
      <div>
        <div className={classes.newPanel}>
          <NewPanel config={this.state.config} updateConfig={config => this.updateConfig(config)} />
        </div>
        <div className={classes.automaton}>
          <SpaceFillingAutomaton config={this.state.config} />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Container);