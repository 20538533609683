import React, { Component } from 'react';

import Big from 'big.js'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';

import SettingsIcon from '@material-ui/icons/Settings'
import CloseIcon from '@material-ui/icons/Close'
import ReplayIcon from '@material-ui/icons/Replay'
import ColorPickerFab from './ColorPickerFab'
import { Config } from '../Types'
import Util from '../Util'
import { Fab } from '@material-ui/core';
import UpdateFunctionFab from './UpdateFunctionFab'
import NumStatesFab from './NumStatesFab'
import AdvancedSettingsFab from './AdvancedSettingsFab';

const styles = (theme: Theme) => createStyles({
  container: {
    padding: "5px",
    display: "flex",
    flexDirection: "column",
  },
  title: {

  },
  controls: {
    display: "flex",
    flexDirection: "row",
  },
  color: {
    width: '36px',
    height: '14px',
    borderRadius: '2px',
  },
  swatch: {
    padding: '5px',
    background: '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
  },
  popover: {
    position: 'absolute',
    zIndex: 2,
    left: '-100px',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: 'rgba(250, 250, 250, 0.8)',
    padding: '5px',
  },
  fab: {
    margin: '5px',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    justifyItems: "center",
  },
  emptyFab: {
    margin: '5px',
    marginRight: '45px', // experimentally determined
  },
  picker: {

  },
  label: {
  },
});

interface Props extends WithStyles<typeof styles> {
  config: Config,
  updateConfig: (config: Config) => void,
}

type State = {
  dialogOpen: boolean,
  tempIndex: Big,
  opened: boolean,
}

// TODO: Should be pure for perf
class NewPanel extends Component<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      dialogOpen: false,
      tempIndex: props.config.updateFunctionId,
      opened: false,
    }
  }

  handleClick = () => {
    this.setState({ dialogOpen: !this.state.dialogOpen })
  };

  handleClose = () => {
    this.setState({ dialogOpen: false })
  };

  handleChange = (index: number, color: string) => {
    let newMap = new Map(this.props.config.colorMap)
    newMap.set(index, color)
    let newConfig = {
      ...this.props.config,
      colorMap: newMap,
    }
    this.props.updateConfig(newConfig)
  };

  handleReset = () => {
    let newConfig = {
      ...this.props.config,
      version: this.props.config.version + 1,
    }
    this.props.updateConfig(newConfig)
  }

  handleTextChange = (ev: any) => {
    this.setState({
      tempIndex: ev.target.value,
    })
  }

  toggleOpened = () => {
    this.setState((prevState, props) => {
      return {
        opened: !prevState.opened,
      }
    })
  }

  updateColor = (index: number, color: string) => {
    this.handleChange(index, color)
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.container}>
        {this.state.opened ? (
          <div className={classes.actions}>
            <div className={classes.label}>
                Advanced
              </div>
            <AdvancedSettingsFab config={this.props.config} updateConfig={config => this.props.updateConfig(config)} />
            <div className={classes.fab}>
              <div className={classes.label}>
                Function
              </div>
              <UpdateFunctionFab config={this.props.config} updateConfig={config => this.props.updateConfig(config)} />
            </div>
            {Util.nums(this.props.config.numStates).map(v => {
              return (
                <div className={classes.fab} key={v}>
                  {v !== 0 ? null : (
                  <div className={classes.label}>
                    Colors
                  </div>
                  )}
                  <ColorPickerFab color={this.props.config.colorMap.get(v) as string} updateColor={color => this.updateColor(v, color)} />
                </div>
              )
            })}
            <div className={classes.fab}>
              <div className={classes.label}>
                States
              </div>
              <NumStatesFab config={this.props.config} updateConfig={config => this.props.updateConfig(config)} />
            </div>
            <div className={classes.fab} >
              <div className={classes.label}>
                Restart
              </div>
              <Fab color="secondary" onClick={() => this.handleReset()}>
                <ReplayIcon />
              </Fab>
            </div>
            <Fab color="secondary" className={classes.fab} onClick={() => this.toggleOpened()} >
              <CloseIcon />
            </Fab>
          </div>
        )
          :
          (
            <div>
              <Fab color="secondary" className={classes.emptyFab} onClick={() => this.toggleOpened()}>
                <SettingsIcon />
              </Fab>
            </div>
          )}
      </div>
    );
  }
}

export default withStyles(styles)(NewPanel);