

import Big from 'big.js'

export enum InitialStateType {
    Single,
    Solid,
    Alternating,
    Random,
}

export type SingleInitialState = {
    state: InitialStateType.Single,
    firstColorIndex: number,
    secondColorIndex: number, // if more than two states
}

export type SolidInitialState = {
    state: InitialStateType.Solid,
    firstColorIndex: number,
    secondColorIndex: number,
}

export type AlternatingInitialState = {
    state: InitialStateType.Alternating,
    firstColorIndex: number,
    secondColorIndex: number,
}

export type RandomInitialState = {
    state: InitialStateType.Random,
    firstColorIndex: number,
    secondColorIndex: number,
    weights?: number[], // weights[0] = 1 and weights[1] = 2 means twice as likely to be second color
}

export type InitialState = 
    | SingleInitialState
    | SolidInitialState
    | AlternatingInitialState
    | RandomInitialState

export enum EdgeHandlingType {
    Constant,
    Alternating,
    Random,
    Wrap,
}

export type ConstantSidesEdgeHandling = {
    t: EdgeHandlingType.Constant,
    leftColorIndex: number,
    rightColorIndex: number,
}

export type AlternatingEdgeHandling = {
    t: EdgeHandlingType.Alternating,
    firstColorIndex: number,
    secondColorIndex: number,
}

export type RandomEdgeHandling = {
    t: EdgeHandlingType.Random,
    weights?: number[],
}

export type WrapEdgeHandling = {
    t: EdgeHandlingType.Wrap,
}

export type EdgeHandling = 
    | ConstantSidesEdgeHandling 
    | AlternatingEdgeHandling 
    | RandomEdgeHandling
    | WrapEdgeHandling

export type Config = {
    version: number,
    numStates: number,
    colorMap: Map<number, string>,
    updateFunctionId: Big,
    initialState: InitialState,
    edgeHandling: EdgeHandling,
}

export type RowInfo = {
    generation: number,
    cells: number[],
}