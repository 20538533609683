import React, { Component } from 'react';

import Automata from './Trace'
import Constants from '../Constants';
import Util from '../Util'
import { AlternatingEdgeHandling, AlternatingInitialState, Config, ConstantSidesEdgeHandling, EdgeHandlingType, InitialStateType, RandomInitialState, RowInfo, SingleInitialState, SolidInitialState } from '../Types'

const DELAY_UPDATE_MS = 250

function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

function getRandomIndex(size: number) {
  return getRandomInt(0, size)
}

function getRandomList(length: number, options: number) {
  var ret: number[] = []
  for (let i=0; i<length; i++) {
    ret.push(getRandomIndex(options))
  }
  return ret
}

function weightedRandom(states: number, weights: number[]) {
  let weightSum = 0
  for (let i=0; i<states; i++) {
    weightSum += weights[i]
  }
  let r = Math.random() * weightSum
  let pastWeightSum = 0
  for (let i=0; i<states; i++) {
    pastWeightSum += weights[i]
    if (pastWeightSum > r) {
      return i
    }
  }

  return states - 1
}

function weightedRandomList(states: number, weights: number[], length: number) {
  return Util.nums(length).map(v => weightedRandom(states, weights))
}

function repeat(value: number, length: number): number[] {
  if (length <= 0) {
    return []
  }
  return repeat(value, length - 1).concat([value])
}

function alternating(first: number, second: number, length: number): number[] {
  if (length <= 0) {
    return []
  }
  return alternating(first, second, length-1).concat([length % 2 === 0 ? first : second])
}

interface Props {
  update: (l: number, c: number, r: number) => number
  config: Config,
}

interface State {
  rows: RowInfo[],
  timeStep: number,
}

class Simulator extends Component<Props, State> {
  private maxNumRows: number = 0

  constructor(props: Props) {
    super(props)

    this.state = {
      rows: [],
      timeStep: 0,
    }

    this.advanceTimeStep = this.advanceTimeStep.bind(this)
  }

  private getInitialCells() {
    let windowHeight = window.screen.availHeight
    let cellsHighFitted = Math.ceil(windowHeight / Constants.CELL_SIZE)
    this.maxNumRows = cellsHighFitted + Constants.BUFFER_CELL_COUNT

    let windowWidth = window.screen.availWidth
    let fittedCells = Math.ceil(windowWidth / Constants.CELL_SIZE)
    let cellsWide = fittedCells + Constants.BUFFER_CELL_COUNT
    console.log("Using cell count: " + cellsWide)

    let config = this.props.config
    let stateType = config.initialState.state
    if (stateType === InitialStateType.Single) {
      let initialState = config.initialState as SingleInitialState
      let repeated = repeat(initialState.secondColorIndex, cellsWide)
      let mid = Math.round(cellsWide / 2)
      repeated[mid] = initialState.firstColorIndex
      return repeated
    } else if (stateType === InitialStateType.Solid) {
      let initialState = config.initialState as SolidInitialState
      return repeat(initialState.firstColorIndex, cellsWide)
    } else if (stateType === InitialStateType.Alternating) {
      let initialState = config.initialState as AlternatingInitialState
      return alternating(initialState.firstColorIndex, initialState.secondColorIndex, cellsWide)
    } else if (stateType === InitialStateType.Random) {
      let initialState = config.initialState as RandomInitialState
      let numStates = this.props.config.numStates
      return initialState.weights !== undefined ? weightedRandomList(numStates, initialState.weights, cellsWide) : getRandomList(cellsWide, this.props.config.numStates)
    }

    throw new Error("Unrecognized initial state type");
  }

  setStateInitial() {
    let rowInfo = {
      cells: this.getInitialCells(),
      generation: 0,
    }
    this.setState({
      rows: [rowInfo],
      timeStep: 0,
    })
  }

  componentDidMount() {
    this.scheduleUpdate()
  }

  componentDidUpdate(nextProps: Props, nextState: State) {
    if (this.props.config.version !== nextProps.config.version) {
      console.log("Version change; resetting")
      this.setStateInitial()
    }
  }

  private scheduleUpdate() {
    setTimeout(() => {
        if (this.state.rows.length === 0) {
          this.setStateInitial()
        } else {
          this.advanceTimeStep()
        }

        this.scheduleUpdate()
    }, DELAY_UPDATE_MS)
  }

  private getLeftEdge(arr: number[]) {
    let edgeHandling = this.props.config.edgeHandling
    let t = edgeHandling.t
    if (t === EdgeHandlingType.Wrap) {
      return arr[arr.length - 1]
    } else if (t === EdgeHandlingType.Constant) {
      return (edgeHandling as ConstantSidesEdgeHandling).leftColorIndex
    } else if (t === EdgeHandlingType.Alternating) {
      let eh = edgeHandling as AlternatingEdgeHandling
      return this.state.rows.length % 2 === 0 ? eh.firstColorIndex : eh.secondColorIndex
    } else if (t === EdgeHandlingType.Random) {
      return getRandomIndex(this.props.config.numStates)
    }
    throw new Error("Unrecognized edge handling type " + t)
  }  

  private getRightEdge(arr: number[]) {
    let edgeHandling = this.props.config.edgeHandling
    let t = edgeHandling.t
    if (t === EdgeHandlingType.Wrap) {
      return arr[0]
    } else if (t === EdgeHandlingType.Constant) {
      return (edgeHandling as ConstantSidesEdgeHandling).rightColorIndex
    } else if (t === EdgeHandlingType.Alternating) {
      let eh = edgeHandling as AlternatingEdgeHandling
      return this.state.rows.length % 2 === 0 ? eh.firstColorIndex : eh.secondColorIndex
    } else if (t === EdgeHandlingType.Random) {
      return getRandomIndex(this.props.config.numStates)
    }
    throw new Error("Unrecognized edge handling type " + t)
  }

  private advanceTimeStep() {
    let lastRow = this.state.rows[this.state.rows.length - 1]
    let nextRow = {
      generation: lastRow.generation + 1,
      // NOTE: changing to be wrap-around. Will now always be set, can change type
      cells: lastRow.cells.map((v, i, arr) => this.props.update(i === 0 ? this.getLeftEdge(arr): arr[i-1], v, i === lastRow.cells.length - 1 ? this.getRightEdge(arr) : arr[i+1]))
    }
    // console.log("next row: " + nextRow)
    this.setState((prevState, props) => {
      // console.log("concated: " + prevState.rows.concat(nextRow))
      let rows = prevState.rows
      if (rows.length >= this.maxNumRows) {
        // console.log("Dropping one row")
        rows.shift()
      }
      return {
        rows: prevState.rows.concat([nextRow]),
        timeStep: prevState.timeStep + 1,
      }
    })
  }

  render() {
    return (
      <div>
          <Automata rows={this.state.rows} config={this.props.config} />
      </div>
    );
  }
}

export default Simulator;