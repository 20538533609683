import React, { Component } from 'react';

import Big from 'big.js'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import { AlternatingInitialState, Config, InitialState, InitialStateType, RandomInitialState, SingleInitialState, SolidInitialState } from '../Types'

import Constants from '../Constants'
import Util from '../Util'

const styles = (theme: Theme) => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  cell: {
    padding: Constants.CELL_SIZE,
    width: 0,
    border: "solid",
  },
  popoverContents: {
    backgroundColor: '#aaaaaa',
    display: "flex",
    flexDirection: 'row',
    alignItems: 'center',
    padding: 5,
  },
  popover: {
    position: 'relative',
    zIndex: 2,
  },
  colorPicker: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  additionalSetting: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  weight: {
    display: 'flex',
    flexDirection: 'row',
  },
  tf: {
    maxWidth: 80,
  },
});

interface Props extends WithStyles<typeof styles> {
  config: Config,
  updateConfig: (config: Config) => void,
  handleClose: () => void,
}

type State = {
  index: number,
  openedPicker: number,
  weights: number[],
}

let singleDefault: SingleInitialState = {
  state: InitialStateType.Single,
  firstColorIndex: 0,
  secondColorIndex: 1,
}

let solidDefault: SolidInitialState = {
  state: InitialStateType.Solid,
  firstColorIndex: 0,
  secondColorIndex: 1,
}

let alternatingDefault: AlternatingInitialState = {
  state: InitialStateType.Alternating,
  firstColorIndex: 0,
  secondColorIndex: 1,
}

let randomDefault: RandomInitialState = {
  state: InitialStateType.Random,
  firstColorIndex: 0,
  secondColorIndex: 1,
}

const stateTypes = [
  ["Single", singleDefault],
  ["Solid", solidDefault],
  ["Alternating", alternatingDefault],
  ["Random", randomDefault],
]

// TODO: Should be pure for perf
class ControlPanel extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      index: 0,
      openedPicker: -1,
      weights: [1,1,1,1],
    };
  }

  handleClose() {
    this.props.handleClose()
  }

  handleSelectUpdateFunction(updateFunctionId: Big) {
    let newConfig = {
      ...this.props.config,
      updateFunctionId: updateFunctionId,
    }
    this.props.updateConfig(newConfig)
    this.handleClose()
  }

  handleChange(event: React.ChangeEvent<{ value: unknown }>) {
    let i = parseInt(event.target.value as string)
    this.setState({
      index: i,
    })
    let newConfig = {
      ...this.props.config,
      initialState: this.getInitialState(i),
    }
    this.props.updateConfig(newConfig)
  }

  getInitialState(i: number): InitialState {
    return stateTypes[i][1] as InitialState
  }

  getInitialStateIndex() {
    let st = this.props.config.initialState.state
    for (let i = 0; i < stateTypes.length; i++) {
      if (st === (stateTypes[i][1] as InitialState).state) {
        return i
      }
    }
    return 0
  }

  handleFirstPicked(i: number) {
    this.setState({
      openedPicker: i,
    })
  }

  handleOutputChange(i: number, c: number) {
    let initialState = this.props.config.initialState
    if (i === 0) {
      initialState.firstColorIndex = c
    } else if (i === 1) {
      initialState.secondColorIndex = c
    }
    let newConfig = {
      ...this.props.config,
      initialState: initialState,
    }
    this.props.updateConfig(newConfig)
    this.setState({
      openedPicker: -1,
    })
  }

  shouldShowColorPickers(initialStateType: InitialStateType) {
    return initialStateType === InitialStateType.Single || initialStateType === InitialStateType.Solid || initialStateType === InitialStateType.Alternating
  }
  
  handleTextChange = (ev: any, i: number) => {
    let initialState = this.props.config.initialState as RandomInitialState
    let weights = initialState.weights
    if (weights === undefined) {
      weights = [1,1,1,1] // NOTE: assumes at most 4 states
    }
    weights[i] = parseInt(ev.target.value)
    initialState.weights = weights
    let newConfig = {
      ...this.props.config,
      initialState: initialState,
    }
    this.props.updateConfig(newConfig)
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Initial State</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={this.getInitialStateIndex()}
            onChange={e => this.handleChange(e)}
          >
            {stateTypes.map((l, i) => <MenuItem key={i} value={i}>{l[0]}</MenuItem>)}
          </Select>
        </FormControl>
        {!this.shouldShowColorPickers(this.props.config.initialState.state) ? null : (
          [0, 1].map(v => {
            let colorIndex = v === 0 ? this.props.config.initialState.firstColorIndex : this.props.config.initialState.secondColorIndex
            return (
              <div className={classes.additionalSetting} key={v}>
                <InputLabel id="demo-simple-select-label">{Util.getOrdinal(v)} color</InputLabel>
                <div className={classes.cell} style={{ backgroundColor: this.props.config.colorMap.get(colorIndex) }} onClick={() => this.handleFirstPicked(v)} />
                {this.state.openedPicker !== v ? null : (
                  <div className={classes.popover} key={v}>
                    <div className={classes.popoverContents}>
                      {Util.nums(this.props.config.numStates).map(v2 => {
                        return (
                          <div key={v2} className={classes.cell} style={{ backgroundColor: this.props.config.colorMap.get(v2) }} onClick={() => this.handleOutputChange(v, v2)} />
                        )
                      })}
                    </div>
                  </div>
                )}
              </div>
            )
          })
        )}
        {this.props.config.initialState.state !== InitialStateType.Random ? null : (
          Util.nums(this.props.config.numStates).map(v => {
            let weights =(this.props.config.initialState as RandomInitialState).weights
            return (
              <div className={classes.additionalSetting} key={v}>
                <InputLabel id="demo-simple-select-label">{Util.getOrdinal(v)} weight</InputLabel>
                <div className={classes.weight}>
                  <div className={classes.cell} style={{ backgroundColor: this.props.config.colorMap.get(v) }} />
                  <TextField
                    className={classes.tf}
                    value={weights === undefined ? 1 : weights[v]}
                    onChange={ev => this.handleTextChange(ev, v)}
                    type="number"
                  />
                </div>
              </div>
            )
          })
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ControlPanel);