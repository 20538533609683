

class Constants {
    DEFAULT_COLOR_MAP = new Map<number, string>()

    constructor() {
        this.DEFAULT_COLOR_MAP.set(0, "#eb9694")
        this.DEFAULT_COLOR_MAP.set(1, "#fef3bd")
        this.DEFAULT_COLOR_MAP.set(2, "#bedadc")
        this.DEFAULT_COLOR_MAP.set(3, "#bed3f3")
        this.DEFAULT_COLOR_MAP.set(4, "#b80000")
        this.DEFAULT_COLOR_MAP.set(5, "#fccb00")
        this.DEFAULT_COLOR_MAP.set(6, "#006b76")
        this.DEFAULT_COLOR_MAP.set(7, "#004dcf")
        this.DEFAULT_COLOR_MAP.set(8, "#fad0c3")
        this.DEFAULT_COLOR_MAP.set(9, "#c1e1c5")
        this.DEFAULT_COLOR_MAP.set(10, "#c4def6")
        this.DEFAULT_COLOR_MAP.set(11, "#d4c4fb")
        this.DEFAULT_COLOR_MAP.set(12, "#db3e00")
        this.DEFAULT_COLOR_MAP.set(13, "#008b02")
        this.DEFAULT_COLOR_MAP.set(14, "#1273de")
        this.DEFAULT_COLOR_MAP.set(15, "#5300eb")
    }

    CELL_SIZE = 10
    BUFFER_CELL_COUNT = 20
    CELLS_PER_ROW = 250
    MAX_NUM_ROWS = 250
    CONTROL_PANEL_MARGIN = '50px'
    CONTROL_PANEL_RIGHT_MARGIN = "5px"

    // 37 is interesting, also 41, 57
    // Holy crap 54
    // 232 is interesting for class 2

    // TODO: Actually figure these out!
    // NOTE: Difference between 3 and 4 is subtle; I definitely messed some up. See: https://plato.stanford.edu/entries/cellular-automata/#Clas256Rule
    CLASSES_TWO_STATES = [
        1, 2, 2, 2, 2, 2, 2, 2, 1, 2, // 0-9
        2, 2, 2, 2, 2, 2, 2, 2, 3, 2, // 10-19
        2, 2, 3, 2, 2, 2, 2, 2, 2, 2, // 20-29
        3, 2, 1, 2, 2, 2, 2, 2, 2, 2, // 30-39
        1, 2, 2, 2, 2, 3, 2, 2, 2, 2, // 40-49
        2, 2, 2, 2, 4, 2, 2, 2, 2, 2, // 50-59
        3, 2, 2, 2, 1, 2, 2, 2, 2, 2, // 60-69
        2, 2, 2, 2, 2, 3, 2, 2, 2, 2, // 70-79 unsure about 73
        2, 2, 2, 2, 2, 2, 3, 2, 2, 3, // 80-89
        3, 2, 2, 2, 2, 2, 1, 2, 2, 2, // 90-99
        // Prediction: 0^3, 2^3, and 4^3 are all class 1; I expected 6^3 would be and it is
        2, 3, 3, 2, 2, 3, 2, 2, 2, 2, // 100-109 unsure about 109
        4, 2, 2, 2, 2, 2, 2, 2, 2, 2, // 110-119
        3, 2, 3, 2, 3, 2, 3, 2, 1, 3, // 120-129
        // Now is when I realize I marked some 4s as 3s -- 4 is randomness propogating in a lattice; 3 is chaotic
        2, 2, 2, 2, 2, 3, 1, 4, 2, 2, // 130-139 unsure about 137
        2, 2, 2, 2, 2, 2, 4, 4, 2, 3, // 140-149
        3, 4, 2, 4, 2, 2, 2, 2, 2, 2, // 150-159
        1, 4, 2, 2, 2, 3, 2, 2, 1, 3, // 160-169 unsure about 169
        2, 2, 2, 2, 2, 2, 2, 2, 2, 2, // 170-179
        2, 2, 4, 4, 2, 2, 2, 2, 2, 2, // 180-189
        2, 2, 1, 4, 2, 4, 2, 2, 2, 2, // 190-199
        2, 2, 2, 2, 2, 2, 2, 2, 2, 2, // 200-209
        2, 2, 2, 2, 2, 2, 2, 2, 2, 2, // 210-219
        2, 2, 2, 2, 1, 4, 2, 2, 2, 2, // 220-229
        2, 2, 2, 2, 1, 1, 2, 2, 1, 1, // 230-239
        2, 2, 2, 2, 2, 2, 2, 2, 1, 1, // 240-249
        1, 1, 1, 1, 1, 1,// 250-255
    ]

    // IDEA: Different categories like "always ends in one state", stable patterns of period 1-5 (5 categories), similar moving ones
    // Could be valuable if we could predict where these would be located, so we can narrow our search for interesting ones
}

export default new Constants()