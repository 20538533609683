import React, { Component } from 'react';

import Big from 'big.js'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import PresetsSection from './PresetsSection'
import CustomSection from './CustomSection'
import CategoriesSection from './CategoriesSection'

import { Config } from '../Types'

const styles = (theme: Theme) => createStyles({
  container: {
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

interface Props extends WithStyles<typeof styles> {
  config: Config,
  updateConfig: (config: Config) => void,
  open: boolean,
  handleClose: () => void,
}

type State = {
  tempIndex: Big,
  tab: number,
}

// TODO: Should be pure for perf
class ControlPanel extends Component<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      tempIndex: props.config.updateFunctionId,
      tab: 0,
    }

    this.handleTabChange = this.handleTabChange.bind(this)
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        tempIndex: this.props.config.updateFunctionId,
      })
    }
  }

  handleClose() {
    this.props.handleClose()
  }

  handleOpen() {
    this.setState({
      tempIndex: this.props.config.updateFunctionId,
    })
  }

  handleUpdate = () => {
    console.log("updating to " + this.state.tempIndex)
    let newConfig = {
      ...this.props.config,
      updateFunctionId: this.state.tempIndex,
    }
    this.props.updateConfig(newConfig)
    this.handleClose()
  }

  handleTextChange = (ev: any) => {
    // console.log("ev: " + util.inspect(ev.currentTarget))
    this.setState({
      tempIndex: Big(ev.target.value),
    })
  }

  handleTabChange(event: React.ChangeEvent<{}>, newValue: number) {
    this.setState({
      tab: newValue,
    })
  }

  updateTempIndex(n: Big) {
    this.setState({
      tempIndex: n,
    })
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.container}>
        <Dialog maxWidth="lg" open={this.props.open} onClose={() => this.handleClose()} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Update Function</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Pick which function index to use as the update function
            </DialogContentText>
            <div>
              <Tabs value={this.state.tab} onChange={this.handleTabChange} aria-label="simple tabs example">
                <Tab label="Presets" />
                <Tab label="Custom" />
                {this.props.config.numStates === 2 ? <Tab label="Categories" /> : null}
              </Tabs>
              <TabPanel value={this.state.tab} index={0}>
                <PresetsSection handleClose={() => this.handleClose()} config={this.props.config} updateConfig={this.props.updateConfig} />
              </TabPanel>
              <TabPanel value={this.state.tab} index={1}>
                <CustomSection tempIndex={this.state.tempIndex} updateTempIndex={n => this.updateTempIndex(n)} handleClose={() => this.handleClose()} config={this.props.config} updateConfig={this.props.updateConfig} />
              </TabPanel>
              <TabPanel value={this.state.tab} index={2}>
              {this.props.config.numStates === 2 ? <CategoriesSection handleClose={() => this.handleClose()} config={this.props.config} updateConfig={this.props.updateConfig} /> : null}
              </TabPanel>

            </div>
            <TextField
              autoFocus
              value={this.state.tempIndex.toFixed()}
              onChange={ev => this.handleTextChange(ev)}
              margin="dense"
              id="update"
              label="Update Index"
              type="number"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose()} color="primary">
              Cancel
              </Button>
            <Button onClick={() => this.handleUpdate()} color="primary">
              Update
              </Button>
          </DialogActions>
        </Dialog>
      </div >
    );
  }
}

export default withStyles(styles)(ControlPanel);