import React, { Component } from 'react';

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';

import Row from './Row'
import { Config, RowInfo } from '../Types'

const styles = (theme: Theme) => createStyles({
  // cellA: {
  //   backgroundColor: '#ff0000',
  // },
  // cellB: {
  //   backgroundColor: '#00ff00',
  //   padding: '10px'
  // },
  // row: {
  //   width: "100%",
  //   display: "flex",
  //   flexDirection: "row",
  //   gap: "0",
  //   justifyContent: "center",
  // }
});

interface Props extends WithStyles<typeof styles> {
  rows: RowInfo[],
  config: Config,
  small?: boolean,
}

type State = {

}

class Trace extends Component<Props, State> {

  render() {

    return (
      <div>
          {this.props.rows.map(v => {
            return (
              <div key={v.generation}>
                <Row cells={v.cells} generation={v.generation} config={this.props.config} small={this.props.small} />
              </div>
            )
          })}
      </div>
    );
  }
}

export default withStyles(styles)(Trace);