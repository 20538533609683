import React, { Component } from 'react';

import Big from 'big.js'

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';

import { Config } from '../Types'

import Util from '../Util'
import Constants from '../Constants'

const styles = (theme: Theme) => createStyles({
  container: {
    // display: "flex",
    // flexDirection: "column",
  },
  swatch: {
  },
  main: {
    zIndex: 0,
  },
  popover: {
    position: 'relative',
    zIndex: 2,
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  picker: {
    position: 'relative',
    left: '-150px',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  custom: {
    maxHeight: '60vh',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    overflow: 'auto',
  },
  rule: {
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  beforeRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  cell: {
    padding: Constants.CELL_SIZE,
    width: 0,
    border: "solid",
  },
  popoverContents: {
    backgroundColor: '#aaaaaa',
    display: "flex",
    flexDirection: 'row',
    alignItems: 'center',
    padding: 5,
  },
});

interface Props extends WithStyles<typeof styles> {
  config: Config,
  updateConfig: (config: Config) => void,
  handleClose: () => void,
  tempIndex: Big,
  updateTempIndex: (n: Big) => void,
}

type State = {
  openedPicker: number,
}

// TODO: Should be pure for perf
class ControlPanel extends Component<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      openedPicker: -1,
    }
  }

  handleClose() {
    this.handleCloseStatePicker()
    this.props.handleClose()
  }

  handleCloseStatePicker() {
    this.setState({
      openedPicker: -1,
    })
  }

  // TODO: dedup with SpaceFillingAutomaton
  private paramUpdate(index: Big, l: number, c: number, r: number) {
    let ll = l ?? 0
    let cc = c ?? 0 // Should never be used
    let rr = r ?? 0
    let radix = this.props.config.numStates
    let x = radix * radix * ll + radix * cc + rr
    // let sizeRequired = Math.ceil(Math.log2(radix))
    // get xth bit of index
    let xth = this.getIth(index, x, radix)
    // console.log("Result for " + index + " and " + x + ": " + xth)
    return xth
  }
  private getIth(n: Big, position: number, radix: number): number {
    let mod = n.mod(radix)
    if (position <= 0) {
      return mod.toNumber()
    } else {
      return this.getIth(n.sub(mod).div(radix), position - 1, radix)
    }
  }

  private handlePicked(index: number) {
    this.setState({
      openedPicker: index,
    })
  }

  // TODO: Probably should have helper library for this kinda code
  // The states are the nth digit in base radix
  private setIth(n: Big, position: number, radix: number, state: number): Big {
    // console.log("setIth update " + n + " with pos " + position + " state " + state)
    if (position <= 0) {
      return n.sub(n.mod(radix)).plus(state)
    }
    let mod = n.mod(radix)
    let n2 = n.sub(mod).div(radix)
    return this.setIth(n2, position - 1, radix, state).times(radix).plus(mod)
  }

  // TODO: UpdateFunctionId should merge inputs with current values?
  private handleOutputChange(index: number, newState: number) {
    console.log("Update index " + index + " to " + newState)
    this.props.updateTempIndex(this.setIth(this.props.tempIndex, index, this.props.config.numStates, newState))
    this.handleCloseStatePicker()
  }

  render() {
    const { classes } = this.props

    let radix = this.props.config.numStates
    let count = radix ** 3

    return (
      <div className={classes.custom} style={this.props.config.numStates === 2 ? { maxWidth: "50vh" } : {}}>
        {Util.nums(count).map(v => {
          // console.log("radix: " + radix)
          let r = v % radix
          let v2 = (v - r) / radix
          // console.log("v2: " + v2)
          let c = v2 % radix
          let v3 = (v2 - c) / radix
          let l = v3 % radix
          // console.log("JACK lcr " + l + " " + c + " " + r)
          let out = this.paramUpdate(this.props.tempIndex, l, c, r)
          return (
            <div className={classes.rule} key={v}>
              <div className={classes.beforeRow}>
                <div className={classes.cell} style={{ backgroundColor: this.props.config.colorMap.get(l) }} />
                <div className={classes.cell} style={{ backgroundColor: this.props.config.colorMap.get(c) }} />
                <div className={classes.cell} style={{ backgroundColor: this.props.config.colorMap.get(r) }} />
              </div>
              <div className={classes.cell} style={{ backgroundColor: this.props.config.colorMap.get(out) }} onClick={() => this.handlePicked(v)} />
              {this.state.openedPicker !== v ? null : (
                <div className={classes.popover} key={v}>
                  <div className={classes.popoverContents}>
                    {Util.nums(this.props.config.numStates).map(v2 => {
                      return (
                        <div key={v2} className={classes.cell} style={{ backgroundColor: this.props.config.colorMap.get(v2) }} onClick={() => this.handleOutputChange(v, v2)} />
                      )
                    })}
                  </div>
                </div>
              )}
              {v}
            </div>
          )
        })}
      </div>
    )
  }
}

export default withStyles(styles)(ControlPanel);